.loading-container {
  display: flex;
  flex-direction: column; /* Arrange the spinner and message vertically */
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--link-color);;
  border-radius: 50%;
  width: 60px; 
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: spin 1s linear infinite;
}

.loading-icon {
  width: 50px; 
  height: 50px;
}

.loading-message {
  margin-top: 10px; /* Space between the spinner and message */
  font-size: 1rem;
  color: var(--link-color);; /* Same color as the spinner border */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
