/* SearchBar.css */
.search-big-container {
    display: flex;
    align-items: center;
    justify-content: center;
   
    width: 100%;
  }
  
  .search-big-input-wrapper {
    display: flex;
    align-items: center;
    background-color: var(--primary-blue-color);
    border-radius: 10px;
    max-width: 500px; /* maximum width of the entire search bar */
    width: 100%;
    padding-right: 10px; /* padding to right side */
  }
  
  .search-big-input {
    width: 100%;
    padding: 15px 15px;
    border: none;
    font-size: 14px;
    color: #fff; /* always white text */
    background-color: var(--primary-blue-color);
    outline: none;
    border-radius: 10px 10px 10px 10px;
  }
  
  .search-big-input::placeholder {
    color: #fff; /* always text color */
  }
  
  .search-big-input:focus::placeholder {
    color: transparent; /* Makes placeholder text invisible when input is focused */
  }
  
  .search-big-button {
    padding: 10px 10px;
    background-color: #000; /* always black */
    color: #fff; /* always white */
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px 50px 50px 50px;
    margin-right: 2.5px; /* Move button slightly to left */
  }
  
  .search-big-icon {
    font-size: 16px;
  }
  