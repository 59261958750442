.error-page {
    text-align: center;
    color: var(--text-color);
    background-color: var(--secondary-bg-color);
    padding-bottom: 50px;
}

.error-page .error-content {
    margin-top: 40px;
}

.error-page .error-content p {
    font-size: 18px;
    color: var(--icon-color);
    margin-bottom: 30px;
}
