.about-page {
  text-align: center;
  background-color: var(--background-color); 
}
  
.about-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
}

.about-content h4 {
  color: var(--text-color);
}
  
.about-content p {
  font-size: 18px;
  line-height: 1.6;
  text-align: left; 
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: var(--text-color);
}

img {
  width: 100%;
  max-width: Auto;
  height: auto;
}

/* GitHub Logo Light Mode  */
:root {
  --icon-color: #181616; 
}

/* GitHub Logo Dark Mode */
[data-theme='dark'] {
  --icon-color: #ffffff; 
}

/* Style for the SVG icon */
.icon {
  color: var(--icon-color); /* Set icon color based on the theme */
  width: 50px; 
  height: 50px; 
}
