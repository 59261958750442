.footer {
    background-color:var(--background-color);
    color: var(--text-color);
    padding: 10px;
    text-align: center;
    font-size: 16px;
  }

/* Footer categories list styling */
.footer-categories {
    list-style: none; 
    padding: 0;
    margin: 20px auto;
    text-align: center;
    display: flex; 
    justify-content: center; 
    gap: 20px; 
    flex-wrap: wrap; 
  }

/* List item styling within footer categories */
.footer-categories li {
    text-align: center; 
    margin: 0;
}

  /* Link styling within footer categories */
.footer-categories a, .footer-categories button {
    text-decoration: none; 
    color: var(--nav-link-color); 
    font-size: 16px;
    cursor: pointer;
    background: none;
    border: none;
}  

/* Hover effect for .footer-link class */
.footer-link:hover, .footer-categories button:hover {
    text-decoration: underline;
  }

/* Footer text styling */
.footer-text{
    margin-top: 30px;
    color: var(--icon-color);
    font-size: 12px;
}

.footer-logo {
  width: 300px;
  height: auto;
}

@media (max-width: 768px) {
  .footer-logo {
      width: 180px;
      height: auto;
  }
}