/* HomePage.css */

.home-page {
  width: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
}

/*************************/
/* Search Section Styles */
/*************************/

.search-section {
  padding: 40px 20px;
  background-color: var(--background-color); 
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.search-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: var(--text-color);
}

.suggested-searches {
  margin-top: 20px;
  color: var(--text-color);
}

.suggested-searches span {
  font-weight: bold;
  margin-right: 10px;
}

.suggested-searches a {
  margin: 0 10px;
  color: var(--link-color);
  text-decoration:none;
}

.suggested-searches a:hover {
  text-decoration: underline;
  color: var(--text-color);
}

/************************************/
/* Featured Products Section Styles */
/************************************/

.featured-products {
  padding: 60px 20px;
  background-color: var(--secondary-bg-color); 
}

.featured-products h2{
  font-size: 28px;
  color: var(--text-color);
  margin-bottom: 10px;
}

.sub-text {
  font-size: 16px;
  color: var(--icon-color);
  margin-bottom: 40px;
}

.product-list {
  display: flex;
  justify-content: center;
  gap: 20px; 
  flex-wrap: wrap; 
}

/***************************/
/* Category Section Styles */
/***************************/

.category-section {
  text-align: center;
  padding: 60px 20px;
  background-color: var(--background-color);
}

.category-section h2 {
  font-size: 28px;
  margin-bottom: 10px;
  color: var(--text-color);
}

.category-section .sub-text {
  font-size: 16px;
  color: var(--icon-color);
  margin-bottom: 40px;
}

.category-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* 3 cards per row */
  gap: 20px; 
  justify-content: center;
  margin-bottom: 40px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.category-card {
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  overflow: hidden;
  height: 75px;
  color: #fff; /* always white text */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.category-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px var(--header-shadow-color);
}

.category-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
}

.category-card-content {
  position: relative;
  z-index: 1;
}

/***************************/
/* Brand Section Styles */
/***************************/

.brand-section {
  text-align: center;
  padding: 60px 20px;
  background-color: var(--secondary-bg-color);
}

.brand-section h2 {
  font-size: 28px;
  margin-bottom: 10px;
  color: var(--text-color);
}

/***********************/
/* Pink buttons styles */
/***********************/

.pink-button {
  max-width: 300px; /* Centers the pink button */
  margin: 0 auto;
}
/***********************/
/* Country selector styles */
/***********************/

/* Styles for the container that holds the search elements */
.search-bar-container {
  display: flex;               /* Use flexbox to arrange child elements */
  align-items: center;         /* Vertically center items */
  justify-content: center;     /* Horizontally center items */
  width: 50%;               /* Set width to one-third of the parent */
}

/* Styles for the SVG icon */
.search-bar-container .size-6 {
  width: 30px;                 
  height: 60px;               
  margin-right: 10px;          /* Add space between the icon and country selector */
}

/* Styles for the country selector dropdown */
.search-bar-container .country-selector {
  height: 40px !important;    
  width: 20% !important;     
  margin-right:  5% !important;     
  border-radius: 10px!important;  
  border-radius: 4px;          /* Maintain border-radius */
  box-sizing: border-box;       /* Ensure proper box model */
  border: 1px solid var(--border-color); /* Keep border style */
  background-color: var(--background-color); /* Keep background color */
  color: var(--text-color);    /* Keep text color */
}

