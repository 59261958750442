.price-comparison {
  margin-top: 40px;
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--header-shadow-color);
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.price-comparison h3 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: var(--primary-blue-color);
  text-align: center;
}
.price{
  font-size: 5px;
}

.retailers {
  display: flex;
  flex-direction: column;
}

.retailer-card {
  display: flex;
  align-items: center;
  background-color: var(--secondary-bg-color);
  padding: 20px;
  border-radius: 10px;
  border: 1px solid var(--border-color);
  box-shadow: 0 2px 4px var(--header-shadow-color);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  margin-bottom: 10px;
}

.retailer-card-no-logo {
  padding: 10px 20px;
  max-width: 1000px;
  margin-left: 10%;
}

.retailer-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px var(--header-shadow-color);;
}

.company-verification-badge {
  display: flex;
  margin-left: auto;
  margin-right: 15px;
  align-items: center;
  position: relative;
}

.verification-tooltip {
  display: none;
  position: absolute;
  bottom: 100%; /* Position above the badge */
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
  padding: 5px;
  border-radius: 5px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 1;
  box-shadow: 0 4px 8px var(--header-shadow-color);
}

.company-verification-badge:hover .verification-tooltip {
  display: block;
}

.verification-tooltip span {
  margin: 0 5px;
}

.retailer-logo {
  width: 150px;
  height: auto;
  margin-right: 20px;
  object-fit: contain;
}

.retailer-info {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.retailer-info p {
  margin: 3px 0;
  font-size: 0.9rem;
  color: var(--text-color);
}

.price {
  font-size: 1.5rem !important;
  font-weight: bold;
  color: var(--primary-blue-color);
  text-align: right; /* Align prices to the right */
  min-width: 100px; /* Set a minimum width to align prices */
  margin: 0; /* Remove any default margin */
}

.price-shipping-container {
  display: flex;
  align-items: center; /* Align items vertically */
  gap: 10px; /* Space between price and shipping icon */
  margin-left: auto; /* Push container to the right */
}

.shipping-icon-container {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 5px;
  position: relative;
}

.shipping-icon {
  font-size: 1.5rem;
  color: var(--primary-blue-color);
  cursor: pointer;
  position: relative;
}

.shipping-tooltip {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--secondary-bg-color);
  color: var(--text-color);
  padding: 5px;
  border-radius: 5px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 1;
  box-shadow: 0 4px 8px var(--header-shadow-color);
}

.shipping-icon-container:hover .shipping-tooltip {
  display: block;
}

.shipping-tooltip span {
  margin: 0 5px;
}

.buy-now {
  flex: 0 0 140px;
  padding: 10px;
  background-color: var(--primary-blue-color);
  color: #fff; /* Always white text */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 1rem;
  transition: background-color 0.2s ease;
  margin-left: 20px;
  max-width: 90px;
  text-decoration: none;
}

.buy-now:hover {
  background-color: var(--dark-blue-color);
}

/* Disabled Button Styling */
.buy-now-disabled {
  flex: 0 0 140px;
  padding: 10px;
  background-color: #ccc; /* Gray out the button */
  color: #666; /* Lighter text color */
  border: none;
  border-radius: 5px;
  cursor: not-allowed; /* Change cursor to not-allowed */
  text-align: center;
  font-size: 1rem;
  margin-left: 20px;
  max-width: 90px;
  text-decoration: none;
}

.buy-now a {
  color: inherit; /* Inherit button text color */
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  line-height: normal;
  cursor: pointer;
}



/* Styling for smartphone */

@media (max-width: 768px) { 

  .price-comparison {
    padding: 15px;
    max-width: 100%;
  }

  .price-comparison h3 {
    font-size: 2rem; 
  }

  .retailer-card {
    flex-direction: column; 
    padding: 15px;
  }

  .retailer-card-no-logo {
    margin-left: 0; 
    max-width: 100%;
  }

  .retailer-logo {
    width: 150px; 
    margin-right: 0;
    margin-bottom: 10px;
  }

  .retailer-info {
    flex-direction: column; 
    align-items: flex-start;
    width: 100%;
  }

  .price {
    font-size: 1.8rem !important; 
    text-align: left; 
    margin-top: 10px;
  }

  .price-shipping-container {
    justify-content: space-between;
    width: 100%; 
    margin-left: 0;
  }

  .shipping-icon-container {
    margin-top: 10px;
  }

  .buy-now {
    flex: auto;
    width: 100%; 
    margin-left: 0;
    margin-top: 10px;
    max-width: none;
  }
}

@media (max-width: 480px) { 

  .price-comparison h3 {
    font-size: 1.8rem; 
  }

  .retailer-logo {
    width: 120px; 
  }

  .price {
    font-size: 1.6rem !important; 
  }
}