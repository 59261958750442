/* BrandLogo.css */

.brand-logos {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 columns per row */
    gap: 20px; /* Space between logos */
    justify-content: center; /* Center grid items horizontally */
    align-items: center; /* Center logos vertically within row */
    margin-bottom: 40px;
    padding: 0 20px; 
    max-width: 1000px; /* Limit grids maximum width */
    margin: 0 auto; /* Center grid container within parent */
    margin-bottom: 40px;
    cursor: pointer; /* Pointer cursor on hover */
    transition: transform 0.2s ease; /* Smooth transition on hover */
  }
  .brand-logos img{
    width: 100px;
    height: auto;
  }

.brand-logo:hover {
  transform: scale(1.05); /* Slightly increase size on hover */
}
