.main-head-title {
  background: linear-gradient(to bottom, 
    #ECF4F4 0%,   
    #DEC4E2 34%,  
    #54B4EA 51%,  
    #0363BB 80%,  
    #023785 100%  
  ); 
  color: white; 
  text-align: center; 
  padding: 120px 60px 20px; 
}

.main-head-title .content {
  max-width: 800px; 
  margin: 0 auto; 
}

.main-head-title h1 {
  font-size: 36px; 
  font-weight: bold; 
  margin-bottom: 30px; 
  color: #fff; /* always white */
}

.main-head-title p {
  font-size: 16px; 
  line-height: 1.5; 
  color: #fff; /* always white */
}

/* Smaller screen styling */
@media (max-width: 768px) {
  .main-head-title {
    padding: 80px 30px 10px; 
  }

  .main-head-title h1 {
    font-size: 28px; 
  }

  .main-head-title p {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .main-head-title {
    padding: 60px 20px 5px; 
  }

  .main-head-title h1 {
    font-size: 22px; 
  }

  .main-head-title p {
    font-size: 12px; 
  }
}
