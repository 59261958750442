.search-page {
  background-color: var(--secondary-bg-color);
}

.search-page-bar-container {
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.search-page-content {
  display: flex;
  width: 100%;
  max-width: 1600px;
  gap: 20px;
}

/* Sidebar initially hidden for small screens */
.search-page-filters-sidebar {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 300px;
  background-color: var(--secondary-bg-color);
  box-shadow: -2px 0 5px var(--shadow-color);
  z-index: 100;
  padding: 20px;
  overflow-y: auto;
}
/* Show sidebar when button is clicked */
.search-page-filters-sidebar.visible {
  display: block;
}
.sort, .price-range {
  margin-bottom: 20px; 
}
.search-page-filter-section {
  margin-bottom: 20px;
}

.search-page-filter-section h4 {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  color: var(--text-color) !important;
}

.search-page-filter-section ul {
  list-style: none;
  padding: 0;
}

.search-page-products-grid {
  flex: 3;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
  gap: 20px;
  padding: 20px;
  width: 100%; 
  position: relative;
}

.search-page-products-grid h3 {
  grid-column: 1 / -1; 
  margin-bottom: 20px;
  font-size: 24px;
  color: var(--text-color);
}

.search-page-pagination {
  margin-top: 20px;
  text-align: center;
}

.search-page-load-more-button {
  padding: 10px 20px;
  background-color: #0363BB;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.search-page-load-more-button:hover {
  background-color: #023768;
}

.search-page-bar-container .search-input {
  width: 100%;
  max-width: 600px;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
}

.search-page-bar-container .search-button {
  background-color: #0363BB;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.search-page-bar-container .search-button:hover {
  background-color: #024f94;
}

/* Close button styling */
.close-button {
  background: none;
  border: solid 1px var(--text-color);
  font-size: 50px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  opacity: 1; 
}
/* Filter button only visible on small screens */
.filter-button {
  background-color: #007bff;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: none; /* Hide by default */
  font-size: 20px;
}

/* For screens wider than 768px */
@media (min-width: 768px) {
  /* Sidebar is visible by default */
  .search-page-filters-sidebar {
    display: block;
    flex-direction: column;
    position: relative;
    width: 20%; 
    height: auto;
    box-shadow: none;
  }
  /* Make sure results-header only takes up one line */
  .results-header {
    grid-column: 1 / -1; /* Span the full width of the grid */
    width: auto; /* Make sure it only takes necessary space */
    margin-bottom: 20px; /* Space below the header */
    color: var(--text-color);
  }


  /* Hide filter button */
  .filter-button {
    display: none;
  }

}
.results-header {
  display: block;
  font-size: 1.5rem;
}

/* For screens smaller than 768px */
@media (max-width: 768px) {
  /* Show filter button */
  .filter-button {
    display: inline-block;
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  /* Sidebar hidden by default */
  .search-page-filters-sidebar {
    display: none;
  }

  /* Sidebar appears as floating window */
  .search-page-filters-sidebar.visible {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background-color: var(--background-color);
    z-index: 100;
    box-shadow: -3px 0 5px var(--shadow-color);
    padding: 10px;
  }

  /* Results and filter button on the same row */
  .results-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  /* Adjust product grid layout for small screens */
  .search-page-products-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
}