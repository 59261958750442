.contact-page {
    text-align: center;
    color: var(--text-color);
    background-color: var(--background-color); 
}

.contact-form-container {
    max-width: 800px;
    padding: 20px;
    margin: 30px auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px var(--header-shadow-color);
}

.contact-form-container p {
    margin-bottom: 20px;
    color: var(--text-color);
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contact-form-group {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.contact-input, .contact-select, .contact-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    color: var(--text-color);
    background-color: var(--background-color); 
    box-sizing: border-box;
}

.contact-textarea {
    height: 150px;
}

.contact-input:focus, .contact-select:focus, .contact-textarea:focus {
    outline: none;
    border-color: var(--focus-color); 
    box-shadow: 0 0 5px var(--focus-color);
}

/* Smaller screens styling */
@media (max-width: 768px) {
    .contact-form-group {
        flex-direction: column; /* Stack vertically on smaller screens */
    }

    .contact-form-container p {
        font-size: 14px;
    }
}