.cookies-div {
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 20px;
    background-color: var(--background-color);
    border-radius: 0 20px 0 0;
    width: 300px;
    height: 200px;
    padding: 20px;
    box-shadow: 0 2px 5px var(--shadow-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    z-index: 900; 
}

.buttons {
    z-index: 901; 
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.buttons p {
    font-size: 10px;
    margin-top: 0;
    color: var(--text-color);
}

.buttons button {
    font-size: 10px;
    color: #000; /* Always Black */
    margin-top: 10px;
    width: 90%;
    height: 40px;
    background-color: var(--light-blue-color);
    border-radius: 10px;
    border: 2px solid var(--background-color);
    cursor: pointer;
    transition: 0.2s linear;

}

.buttons button:hover {
    background-color: var(--primary-blue-color);
}

.buttons h4:hover{
    text-decoration: underline;
}

@media (max-width: 768px) {
    .cookies-div{
        margin-top: 65px;
        height: 250px;
        position: fixed;
        bottom: 0;
        width: 90%;
        border-radius: 0;
    }
}