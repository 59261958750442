.pink-button {
  border: none; 
  cursor: pointer;
  box-shadow: 0 4px 6px var(--shadow-color);
  max-width: 300px;
  margin: 0 auto;
  display: block;
  padding: 10px 20px;
  background-color: #dba7c5;
  color: black;
  border-radius: 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}
  
.pink-button:hover {
  background-color: #c48fb1;
}