.brand-page{
    background-color: var(--background-color);
    min-height: 100vh;
}

.brand-category-buttons {
    display: flex; /* Use flexbox for layout */
    flex-wrap: wrap; /* Allow buttons to wrap onto multiple lines */
    justify-content: flex-start; /* Align buttons to the start */
    margin: 20px; /* Margin around the category buttons */
}

.brand-category-buttons .pink-button {
    margin: 5px; /* Adjust spacing between buttons */
    padding: 5px 10px; /* Add padding for clickable area */
}

.brand-list {
    display: grid; /* Use CSS Grid layout */
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Responsive columns */
    gap: 10px; /* Space between grid items */
    margin-top: 20px; /* Optional: Add top margin to separate from buttons */
}

.brand-list li {
    list-style: none; /* Remove bullet points */
    text-align: left; /* Left align text */
    padding: 5px; /* Add padding for better spacing */
}

.brand-button {
    background-color: var(--secondary-bg-color); /* Set background color */
    color: var(--text-color); /* Set text color */
    border: solid 1px #093f79; /* Set border color */
    padding: 10px 15px; /* Add padding */
    cursor: pointer; /* Pointer cursor on hover */
    border-radius: 5px; /* Rounded corners */
    margin: 5px; /* Margin around buttons */
    transition: background-color 0.3s ease; /* Smooth transition for background color */
    min-width: 140px; /* Minimum width for buttons */
    height: 40px; /* Fixed height for buttons */
    display: flex; /* Use flexbox for button content */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    font-weight: bold; /* Bold font for button text */
}

.brand-button:hover {
    background-color: var(--primary-pink-color); /* Background color on hover */
    color: black; /* Text color on hover */
}

.pagination-controls {
    display: flex; /* Use flexbox for pagination controls */
    justify-content: space-between; /* Space between pagination buttons */
    margin-top: 20px; /* Space above pagination controls */
}

.pagination-controls button {
    background-color: var(--primary-pink-color); /* Button color */
    color: black; /* Text color */
    border: none; /* Remove border */
    border-radius: 5px; /* Rounded corners */
    padding: 5px 10px; /* Padding for buttons */
    cursor: pointer; /* Pointer cursor on hover */
}

.pagination-controls button:disabled {
   white-space: var(--icon-color); /* Grey out disabled buttons */
    cursor: not-allowed; /* Not-allowed cursor for disabled buttons */
}

pagination-controls button:hover{
    background-color: var(--dark-pink-color);
}
