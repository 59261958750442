.sort-component {
    margin-bottom: 20px; /* Space from other elements */ 
  }
  
  .sort-component h4 {
    margin-right: 10px;
    margin-bottom: 20px;
    color: var(--text-color);
  }
  
  .sort-component select {
    padding: 5px; 
    border-radius: 4px; 
    width: 100%; 
    box-sizing: border-box; 
    border: 1px solid var(--border-color); 
    background-color: var(--background-color); 
    color: var(--text-color);
 }
 
 .line {
  display: inline-block;
  width: 3px; /* Adjust width as needed */
  height: 20px; /* Line height */
  background-color: var(--text-color); /* Black line color */
  margin-right: 10px;
  margin-top: 3px; /* Space between line and text */
}
 /* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .sort-component {
    margin-bottom: 15px; /* Reduce margin for small screens */
  }

  .sort-component select {
    width: 100%; /* Ensure dropdown fits container width */
  }
  
}

  
  