/* ProductCard.css */

.product-card {
  width: 200px; 
  border-radius: 15px; 
  background-color: var(--background-color); 
  box-shadow: 0 4px 6px var(--header-shadow-color); /* Subtle shadow for effect */
  text-align: center; 
  padding: 10px; 
  margin: 10px;
  text-decoration: none; /* Remove underline from link */
  color: var(--text-color);
  transition: transform 0.2s ease; /* Smooth transition on hover */
  cursor: pointer; /* Show pointer cursor on hover */
  border: none; /* Remove default border */
}

.product-card:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
}

.product-image {
  width: 100%;
  height: 200px; 
  background-color: var(--secondary-bg-color); 
  border-radius: 10px; 
  overflow: hidden; 
}

.product-image img {
  width: 100%;
  height: 100%;
  object-fit: scale-down; 
}

.product-name {
  font-size: 16px; 
  font-weight: normal;
  color: var(--primary-blue-color); 
  margin: 10px 0; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  max-width: 90%; 
}
.product-date{
  font-size: 16px; 
  font-weight: normal;
  color: var(--icon-color); 
}
.remove-button{
  height: 30px;
  margin: 5px;
  width: 80%;
  border: none;
  background-color: var(primary-pink-color);
  border-radius: 15px;
  transition: 0.3s ease;
}
.remove-button:hover{
  background-color: var(dark-pink-color);
}
